export const environment = {
  name: 'demo',
  production: false,
  payment: {
    stripe: {
      publicKey:
        'pk_test_51LTKwVGMJrJk08ARZQJQPLd6g1ryl0M4fAPIUe2G5bnZGEv21igJcZaoyLIkjW0omAyXxEpoE7AD3YyLMieZxgsW00LfyBby9B',
    },
  },
  url: {
    graphQL: 'https://onep-gateway.demo.onep.wedoogift.com/graphql',
    glady: {
      home: 'https://home-page.demo.tech.glady.com',
      manager: 'https://manager.demo.glady.com',
    },
    payment: {
      success: 'https://pay.demo.glady.com/payment-success',
    },
  },
  email: {
    sav_order: 'sav.commande@glady.com',
    manager: 'manager@glady.com',
  },
};
